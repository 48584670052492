<template>
  <div>
    <div style="height: 70px"></div>
    <div id="Nav" :class="{ 'nav-bgc': isIndex, 'nav-login-bgc': loginPage }">
      <div class="header-box">
        <div class="nav-left">
          <a>
            <img class="logo" src="@/assets/image/nav/logo.png" alt="" />
          </a>
          <div v-if="isIndex" class="title text">
            <span class="title-l">用 知 识 为 爱 赋 能</span>
          </div>
        </div>
        <div class="nav-right text">
          <span class="time">数据统计时间截至为：{{ time }}</span>
          <div class="sign">
            <a-dropdown>
              <a>
                <img class="person-l" :src="GLOBAL.defaultUserImg" alt="" />
                <span class="person-r">{{ channelName }}</span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item
                  key="overlay2"
                  class="login-out"
                  @click="FunLoginOut()"
                >
                  <a-icon type="poweroff" />
                  <span>退出系统</span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="order-list">
      <h4 class="big-title">销售数据</h4>
      <div class="table-inner">
        <!-- 查询条件 -->
        <div class="search-top">
          <div>
            <span>商品名称：</span>
            <a-input
              class="input"
              v-model="queryParms.productName"
              placeholder="请输入商品名称"
              allowClear
            />
          </div>
          <div>
            <span>支付状态：</span>
            <a-select
              style="width: 120px"
              placeholder="支付状态"
              v-model="queryParms.status"
              allowClear
            >
              <a-select-option value="0">交易完成</a-select-option>
              <a-select-option value="1">待卖家处理</a-select-option>
              <a-select-option value="2">部分退款</a-select-option>
              <a-select-option value="3">已退款</a-select-option>
              <a-select-option value="4">退款关闭</a-select-option>
            </a-select>
          </div>
          <div>
            <span>支付时间：</span>
            <a-range-picker
              v-model="datatime"
              show-time
              format="YYYY-MM-DD hh:mm:ss"
            />
          </div>
          <p class="all-btn-small" @click="onSearch">搜索</p>
        </div>
        <p class="total-price">销售总额：{{ totalPrice }}元</p>
        <a-table
          class="table-template"
          :rowKey="(item, index) => index"
          :columns="columns"
          :loading="loading"
          :data-source="tableData"
          @change="onPage"
          :pagination="{
            total: total,
            current: queryParms.pageNum,
            defaultPageSize: queryParms.pageSize,
            showSizeChanger: true,
            showTotal: function (total, range) {
              return `共${total}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (queryParms.pageNum - 1) * queryParms.pageSize + i + 1 }}
            </div>
          </template>
          <template slot="address" slot-scope="item">
            <span>{{item.addressProvincialName}}-</span>
            <span>{{item.addressCityName}}</span>
            <span>-{{item.addressAreaName}}</span>
          </template>
          <template slot="refundStatus" slot-scope="text">
            <span
              :class="
                text == 1
                  ? 'color3'
                  : text == 2
                  ? ''
                  : text == 3
                  ? ''
                  : text == 4
                  ? 'color1'
                  : 'color2'
              "
            >
              {{
                text == 1
                  ? "待卖家处理"
                  : text == 2
                  ? "部分退款"
                  : text == 3
                  ? "已退款"
                  : text == 4
                  ? "退款关闭"
                  : "交易完成"
              }}</span
            >
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "地区",
    align: "center",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "交易时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "实付款",
    align: "center",
    dataIndex: "payPrice",
  },
  {
    title: "支付状态",
    align: "center",
    dataIndex: "refundStatus",
    scopedSlots: { customRender: "refundStatus" },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [],
  // 数据对象
  data() {
    return {
      columns,
      loginPage: true, //是否为登录页面
      isIndex: true,
      time: "",
      queryParms: {
        productName: "",
        status: undefined,
        start: "",
        end: "",
        pageNum: 1,
        pageSize: 10,
      },
      datatime: [],
      channelName: "",
      tableData: [],
      total: 0,
      loading: false,
      totalPrice: 0,
    };
  },
  // 事件处理器
  methods: {
    onPage(e) {
      this.queryParms.pageNum = e.current;
      this.queryParms.pageSize = e.pageSize;
      this.getTableList();
    },
    FunLoginOut() {
      this.$router.replace("/login/customerLogin");
      sessionStorage.removeItem("orderToken");
    },
    // 获取信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/channel/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.channelName = res.data.channelName;
        }
      });
    },

    onSearch() {
      this.queryParms.pageNum = 1;
      this.getTableList();
    },
    getTableList() {
      if (this.datatime.length) {
        this.queryParms.start = moment(this.datatime[0]).format(
          "YYYY-MM-DD hh:mm:ss"
        );
        this.queryParms.end = moment(this.datatime[1]).format(
          "YYYY-MM-DD hh:mm:ss"
        );
      } else {
        this.queryParms.start = "";
        this.queryParms.end = "";
      }
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/order/channel/orders",
        method: "get",
        params: this.queryParms,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }

        this.getTime();
      });
    },
    getTotalPrice() {
      this.$ajax({
        url: "/hxclass-pc/order/channel/orders/sum",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.totalPrice = res.data;
        }
      });
    },

    getTime() {
      const date = new Date();
      this.time = moment(date).format("YYYY-MM-DD") + " 00:00:00";
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getTime();

    this.getUserInfo();
    this.getTableList();
    this.getTotalPrice();
  },
  beforeDestroy() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
#Nav {
  background-color: @bg-color;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.nav-bgc {
  background-color: rgba(10, 117, 146, 0.8) !important;
}
// .nav-login-bgc {
//   background-color: rgba(10, 117, 146, 1) !important;
// }

.header-box {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 1200px;
  height: 100%;

  .text {
    height: 24px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #ffffff;
    line-height: 70px;
    letter-spacing: 4px;
  }

  .nav-left {
    display: flex;
    .logo {
      margin-right: 78px;
      width: 154px;
      height: 68px;
    }
    .title {
      color: rgba(255, 255, 255, 0.8);
      .title-r {
        letter-spacing: 2px;
      }
    }
    .menu {
      display: flex;
      cursor: pointer;
      .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 184px;
        height: 70px;
        font-size: 18px;
        font-family: PingFangMedium;
        color: rgba(255, 255, 255, 1);
        .menu-top {
          margin-bottom: 6px;
          font-weight: 400;
        }
        .menu-bot {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .menu-bgc {
        color: rgba(255, 255, 255, 0.95);
        background: rgba(0, 0, 0, 0.19);
        .menu-top {
          font-weight: 500;
        }
        .menu-bot {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .nav-right {
    display: flex;
    letter-spacing: 1px;
    .time {
      font-size: 12px;
      margin-right: 50px;
    }
    .sign {
      margin-right: 60px;
      .person-l {
        margin-right: 12px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      img {
        cursor: pointer;
      }
      span {
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.order-list {
  width: 1200px;
  margin: 20px auto;
  .big-title {
    font-size: 24px;
    font-family: PingFangMedium;
    color: #333;
    line-height: 22px;
    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 21px;
      background: #15b7dd;
      border-radius: 1.5px;
      margin-right: 4px;
      margin-bottom: -2px;
    }
  }
  .table-inner {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    .search-top {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        margin-right: 30px;
        span {
          min-width: 80px;
          font-size: 14px;
        }
        .input {
          width: 220px;
        }
      }
      .all-btn-small {
        width: 110px;
        line-height: 38px;
        height: 38px;
      }
    }
    .total-price {
      margin: 20px 0 10px 0;
      font-size: 16px;
      color: @theme;
    }
    .color1 {
      color: #fa8072;
    }
    .color2 {
      color: #32cd32;
    }
    .color3 {
      color: #ff8c00;
    }
  }
}
</style>
